


















import ScholarAnalyticsComponent from '@/components/analyticsComponent/scholarAnalyticsComponent/ScholarAnalyticsComponent';
export default ScholarAnalyticsComponent;
